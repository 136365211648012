import React, { useState } from 'react';
import mapping from './mapping.json';
import './App.css';
import overlayImageSrc from './images/overlay.png';


function App() {
    const [nftNumber, setNftNumber] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [previewImage, setPreviewImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const copyToClipboard = () => {
        const donateAddressElement = document.getElementById("donateAddress");
        const addressToCopy = donateAddressElement.getAttribute("data-address");

        navigator.clipboard.writeText(addressToCopy).then(() => {
            alert("Address copied to clipboard");
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };


    const devices = [
        { name: 'iPhone X / XS / 11 Pro / 12 Mini', size: '1125x2436' },
        { name: 'iPhone XR / 11 / 12 / 12 Pro / 13 / 13 Pro / 14 / 14 Pro / 15 / 15 Pro', size: '1170x2532' },
        { name: 'iPhone XS Max / 11 Pro Max / 12 Pro Max / 13 Pro Max / 14 Pro Max / 15 Pro Max', size: '1284x2778' },
        { name: 'Samsung Galaxy S21 / S21+ / S21 Ultra / S23 / S23+ / S23 Ultra', size: '1080x2400' },
        { name: 'Samsung Galaxy S22 / S22+ / S22 Ultra', size: '1080x2340' },
        { name: 'Google Pixel 5 / 6 / 6 Pro', size: '1080x2340' },
        { name: 'Google Pixel 7 / 7 Pro / 8 / 8 Pro', size: '1440x3120' },
        { name: 'Desktop Full HD (1920x1080)', size: '1920x1080' },
        { name: 'Desktop 2.5K (QHD, 2560x1440)', size: '2560x1440' },
        { name: 'Desktop 4K (UHD, 3840x2160)', size: '3840x2160' },
    ];


    const handleNftNumberChange = (e) => {
        const num = e.target.value;
        setNftNumber(num);
        setSelectedDevice(''); // Setzt die Geräteauswahl zurück

        if (!/^\d+$/.test(num) || parseInt(num) < 0 || parseInt(num) > 5000) {
            setErrorMessage('Please enter a number between 0 and 5000.');
            setPreviewImage(null);
            return;
        }

        setErrorMessage('');
        const imageUrl = mapping[num];
        if (imageUrl) {
            setPreviewImage(imageUrl);
        } else {
            setPreviewImage(null);
        }
    };

    const handleDeviceChange = (e) => {
        setSelectedDevice(e.target.value);
        const imageUrl = mapping[nftNumber];
        if (imageUrl && e.target.value) {
            resizeImage(imageUrl, e.target.value);
        }
    };

    const resizeImage = (imageUrl, size = '600x600') => {
        const [targetWidth, targetHeight] = size.split('x').map(Number);
        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const ctx = canvas.getContext('2d');
        const img = new Image();
        const overlayImage = new Image();

        img.crossOrigin = 'anonymous';
        overlayImage.crossOrigin = 'anonymous';
        overlayImage.src = overlayImageSrc;

        img.onload = () => {
            let newWidth, newHeight;

            switch (size) {
                case '1920x1080': // Full HD
                    newWidth = newHeight = 650;
                    break;
                case '2560x1440': // 2.5K
                    newWidth = newHeight = 850;
                    break;
                case '3840x2160': // 4K
                    newWidth = newHeight = 1500;
                    break;
                default: // Andere Größen
                    newWidth = newHeight = 1000;
                    break;
            }

            if (img.width > img.height) {
                newHeight = (img.height / img.width) * newWidth;
            } else {
                newWidth = (img.width / img.height) * newHeight;
            }

            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = img.width;
            tempCanvas.height = img.height;
            const tempCtx = tempCanvas.getContext('2d');
            tempCtx.drawImage(img, 0, 0);
            const bgColor = tempCtx.getImageData(0, img.height - 1, 1, 1).data;

            console.log(img.width, img.height)
            ctx.fillStyle = `rgba(${bgColor[0]}, ${bgColor[1]}, ${bgColor[2]}, ${bgColor[3] / 255})`;
            ctx.fillRect(0, 0, targetWidth, targetHeight);

            const x = (targetWidth - newWidth) / 2;
            const y = targetHeight - newHeight;
            ctx.drawImage(img, x, y, newWidth, newHeight);
            ctx.fillRect(x - 10, y, 100, 100);

            const overlayImage = new Image();
            overlayImage.src = overlayImageSrc;
            overlayImage.onload = () => {
                let overlayX = (targetWidth - overlayImage.width) / 2;
                let overlayY;

                switch (size) {
                    case '1920x1080': // Full HD
                        overlayY = 50; // 200px unterhalb des oberen Bildrandes
                        break;
                    case '2560x1440': // 2.5K
                        overlayY = 150; // 100px unterhalb des oberen Bildrandes
                        break;
                    case '3840x2160': // 4K
                        overlayY = 250; // 100px unterhalb des oberen Bildrandes
                        break;
                    default:
                        overlayY = (targetHeight - overlayImage.height) / 2 - 100; // Standardpositionierung für andere Größen
                        break;
                }

                ctx.drawImage(overlayImage, overlayX, overlayY);
                setPreviewImage(canvas.toDataURL());
            };

            setPreviewImage(canvas.toDataURL());
        };

        img.src = imageUrl;
    };

    const downloadImage = () => {
        if (!previewImage) return;
        const link = document.createElement('a');
        link.href = previewImage;
        link.download = `kromes-${nftNumber}-wallpaper.png`;
        link.click();
    };

    return (
        <div className="app-container">
            <img src={require('./images/logo.png')} className="header-logo" />
            <h1>Wallpaper generator</h1>
            <div className="form-container">
                <input
                    type="text"
                    placeholder="Enter NFT number (1 - 5000)"
                    value={nftNumber}
                    onChange={handleNftNumberChange}
                />
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <select value={selectedDevice} onChange={handleDeviceChange}>
                    <option value="">Select Device</option>
                    {devices.map((device, index) => (
                        <option key={index} value={device.size}>{device.name}</option>
                    ))}
                </select>
                <button onClick={downloadImage}>Download Image</button>
            </div>
            {previewImage && <img src={previewImage} alt="Preview" className="nft-image" />}
            <div className="footer" onClick={copyToClipboard}>
                <p className="footer-text">Solana address for donations: </p>
                <span id="donateAddress" data-address="2d7BUhdvWmat4fVx82pijoZ5jh9LBeN2TsVVEcesMUm7">2d7B...sMUm7</span>
                <span>(click to copy)</span>
            </div>
        </div>
    );
}

export default App;
